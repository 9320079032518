
/* search start*/
#searchContainer
{
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #0c0c0c;
	z-index: 10000;
}
#searchContainer.show
{
	-webkit-animation: bounceInLeft .75s;
	-o-animation: bounceInLeft .75s;
	animation: bounceInLeft .75s;
	animation-fill-mode: both;
}
#searchContainer.show.close
{
	animation: bounceOutLeft .75s;
	animation-fill-mode: forwards;
}
.search-container
{
	margin-bottom: 30px;
}
.search-container *
{
	-webkit-transition: all .567s;
	-moz-transition: all .567s;
	-ms-transition: all .567s;
	-o-transition: all .567s;
	transition: all .567s;
}
.search-container .program
{
	float: none;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}
.search-container .program__img-link
{
	width: 100%;
	position: relative;
}
.search-container .program img
{
	width: 100%;
}
.search-header
{
	display: table;
	width: 100%;
	height: 120px;
	border-bottom: 1px solid #181818;
}
.search-header > div
{
	display: table-cell;
	vertical-align: middle;
	font-size: 0;
}
.search-header .search-back,
.search-header .header-content
{
	display: inline-block;
	vertical-align: middle;
}
.search-header .search-back
{
	width: 60px;
	height: 60px;
	background-image: url("./../images/arrow_left.svg");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 100% auto;
	cursor: pointer;
}
.search-header .header-content
{
	position: relative;
	width: calc(100% - 60px);
}
::-ms-clear{display: none;}
::-ms-reveal{display: none;}
.search-input
{
	height: 60px;
	width: 100%;
	padding-right: 15px;
	font-size: 48px;
	font-weight: 600;
	color: #eee;
	caret-color: #00c473;
	background-color: transparent;
	border: none;
	outline: none;
}
.search-input::-webkit-input-placeholder,
.search-input::-moz-placeholder,
.search-input:-moz-placeholder,
.search-input::-ms-input-placeholder,
.search-input:placeholder-shown
{
	color: #2e2e2e;
}
.search-delete
{
	display: none;
	position: absolute;
	right: 0px;
	top: 10px;
	width: 15px;
	height: 40px;
	background-image: url("./../images/close_white.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 15px auto;
	cursor: pointer;
}
.search-result
{
	overflow: hidden;
}
.search-result .no-content
{
	padding: 80px 0;
	text-align: center;
	font-size: 24px;
	color: #fff;
	font-weight: 600;
}
.more
{
	display: none;
	text-align: center;
}
.load-more
{
	margin: 30px auto;
	width: 200px;
	height: 50px;
	line-height: 50px;
	font-weight: 700;
	font-size: 14px;
	display: block;
	color: #fff;
	text-align: center;
	background-color: rgba(0, 196, 115, 0.64);
	transition: all 0.63s cubic-bezier(0.19, 1, 0.22, 1);
	border-radius: 25px;
}
.load-more:hover
{
	background-color: rgba(0, 196, 115, 1);
}
@media (max-width: 1027px)
{
	.search-input
	{
		font-size: 30px;
	}
}
@media (max-width: 767px)
{
	.search-input
	{
		font-size: 24px;
	}
	.search-header .search-back
	{

		width: 40px;
	}
	.search-header .header-content
	{
		width: calc(100% - 40px);
	}
}
@media (max-width: 483px)
{
	.search-header
	{
		height: 61px;
	}
	.search-header .search-back
	{
		width: 34px;
	}
	.search-header .header-content
	{
		width: calc(100% - 30px);
		margin-left: -6px;
	}
	.search-input
	{
		font-size: 16px;
		padding-left: 4px;
	}
}
@-webkit-keyframes bounceInLeft
{
	from, 60%, 75%, 90%, to
	{
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0%
	{
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}

	60%
	{
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}

	75%
	{
		transform: translate3d(-10px, 0, 0);
	}

	90%
	{
		transform: translate3d(5px, 0, 0);
	}

	to
	{
		transform: none;
	}
}
@-moz-keyframes bounceInLeft
{
	from, 60%, 75%, 90%, to
	{
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0%
	{
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}

	60%
	{
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}

	75%
	{
		transform: translate3d(-10px, 0, 0);
	}

	90%
	{
		transform: translate3d(5px, 0, 0);
	}

	to
	{
		transform: none;
	}
}
@-o-keyframes bounceInLeft
{
	from, 60%, 75%, 90%, to
	{
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0%
	{
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}

	60%
	{
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}

	75%
	{
		transform: translate3d(-10px, 0, 0);
	}

	90%
	{
		transform: translate3d(5px, 0, 0);
	}

	to
	{
		transform: none;
	}
}
@keyframes bounceInLeft
{
	from, 60%, 75%, 90%, to
	{
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0%
	{
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}

	60%
	{
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}

	75%
	{
		transform: translate3d(-10px, 0, 0);
	}

	90%
	{
		transform: translate3d(5px, 0, 0);
	}

	to
	{
		transform: none;
	}
}

@keyframes bounceOutLeft
{
	from, 20%, to
	{
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	20%
	{
		transform: translate3d(20px, 0, 0);
	}

	to
	{
		transform: translate3d(-2000px, 0, 0);
	}
}
/* search end*/
