/* Program Start */
.program
{
	margin: 15px 0;
	overflow: hidden;
	line-height: 24px;
}
.program--table
{
	display: table;
}
.program-wrap
{
	display: inline-block;
}
.program__content--table
{
	display: table;
}
.program__detail
{
	font-weight: 600;
	position: relative;
	padding-right: 40px;
}
.program__detail--table
{
	display: table-cell !important;
	vertical-align: middle;
}
.program__detail--margin
{
	margin: 10px 0;
}
.program__title
{
	font-weight: 700;
	font-size: 24px;
	color: #eee;
}
.schedule-list .program__title
{
	line-height: 24px;
	max-height: 48px;
	display: -webkit-box;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.program__title:hover
{
	text-decoration: underline;
}
.program__title--size-small
{
	font-size: 16px;
}
.program__info
{
	font-size: 12px;
}
.program__info--size-big
{
	font-size: 14px;
}
.program__info--size-bigger
{
	font-size: 16px;
}
.program__info--font-regular
{
	font-weight: 400;
}
.program__info--margin
{
	margin-top: 18px;
}
.dot
{
	margin: 0 3px 0 3px;
}
.program__blackout-section
{
	display: none;
}
.program__blackout
{
	color: #eee;
}
.program__text-link
{
	color: #55b876;
	text-decoration: underline;
}
.program__img-link
{
	display: inline-block;
}
.program__img
{
	margin: auto;
	width: 100%;
	transition: all .25s ease;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-o-transition: all .25s ease;
}
.category__tag
{
	position: absolute;
	right: 10px;
	top: 10px;
}
.category__tag.tag-img-es
{
	width: auto;
	height: 28px;
	top: 6px;
	right: 6px;
}
.category__tag.tag-img-eb
{
	width: auto !important;
	height: 28px;
	top: 8px;
	right: 8px;
}
.category__tag.tag-img-bg
{
	width: auto;
	height: 50px;
	right: 26px;
	top: 26px;
}
.owl-item .category__tag.tag-img-eb
{
	top: 5px;
	right: 5px;
	height: 28px;
}
@media (max-width: 767px)
{
	.category__tag.tag-img-eb
	{
		height: 50px;
		top: 10px;
		right: 10px;
	}
	.category__tag.tag-img-bg
	{
		right: 8px;
		top: 8px;
		height: 36px;
	}
}
@media (max-width: 483px)
{
	.category__tag.tag-img-eb
	{
		height:28px;
		top: 4px;
		right: 4px;
	}
	.owl-item .category__tag.tag-img-eb
	{
		top: 4px;
		right: 4px;
		height: 14px;
	}
}
.owl-carousel .owl-item img.category__tag
{
	width: auto;
}
.program__img-wrap
{
	position: relative;
	display: block;
	overflow: hidden;
}
.program__img-wrap:hover .program__img
{
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03);
}
.program__img--linehight0
{
	line-height: 0;
}
.program__img-wrap--table
{
	display: table-cell;
	vertical-align: middle;
}
.program__img--margin
{
	margin: 0 30px;
}
.program__img-content
{
	position: relative;
	display: inline-block;
}
.program__img--default
{
	width: 262px;
	height: 147px;
}
.program__mask
{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .35);
	opacity: 0;
	transition: all .25s ease;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-o-transition: all .25s ease;
}
.purchase-type-0 .program__mask:hover,
.channel-neu-access .program__mask:hover,
body.isDTCUser .purchase-type-1 .program__mask:hover,
body.isDTCUser .purchase-type-3 .program__mask:hover,
body.isAnonymous.isFreePreview .purchase-type-1 .program__mask:hover,
body.isAnonymous.isFreePreview .purchase-type-2 .program__mask:hover,
body.isAnonymous.isFreePreview .purchase-type-3 .program__mask:hover,
body.isAnonymous.isFreePreview .channel-neu-noaccess .program__mask:hover,
body.tve-auth-univision .tve-purchase-univision .program__mask:hover,
body.tve-auth-galavision .tve-purchase-galavision .program__mask:hover,
body.tve-auth-deportes .tve-purchase-deportes .program__mask:hover,
body.tve-auth-fusion .tve-purchase-fusion .program__mask:hover,
body.tve-auth-univision .purchase-type-1 .program__mask:hover,
body.tve-auth-univision .purchase-type-2 .program__mask:hover,
body.tve-auth-galavision .purchase-type-1 .program__mask:hover,
body.tve-auth-galavision .purchase-type-2 .program__mask:hover,
body.tve-auth-deportes .purchase-type-1 .program__mask:hover,
body.tve-auth-deportes .purchase-type-2 .program__mask:hover,
body.tve-auth-fusion .purchase-type-1 .program__mask:hover,
body.tve-auth-fusion .purchase-type-2 .program__mask:hover
{
	opacity: 1;
}
body.isVIP .program__mask:hover
{
	opacity: 1!important;
}
body.isTemporaryUser .program__mask:hover
{
	opacity: 1!important;
}
.program__bar
{
	display: none;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
	background: rgba(255, 255, 255, .3);
}
.enablePs .program__bar
{
	display: block;
}
.program__progress
{
	height: 100%;
	background: #00c473;
}
.program__icon
{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 48px;
	height: 48px;
}
.program--icon-play
{
	background: url('../images/program_icon_play.svg') no-repeat;
}
.program--icon-lock
{
	display: none;
	background: url('../images/program_icon_lock.svg') no-repeat;
}
.program--icon-lock.program--icon-lock-placeholder
{
	background: none;
	opacity: 0;
}
body.isAnonymous .purchase-type-1 .program--icon-lock,
body.isAnonymous .purchase-type-2 .program--icon-lock,
body.isAnonymous .purchase-type-3 .program--icon-lock,
body.isLogin .purchase-type-1 .program--icon-lock,
body.isLogin .purchase-type-2 .program--icon-lock,
body.isLogin .purchase-type-3 .program--icon-lock,
body.isDTCUser .purchase-type-1 .program--icon-lock,
body.isDTCUser .purchase-type-2 .program--icon-lock,
body.tve-auth-univision .tve-purchase-galavision .program--icon-lock,
body.tve-auth-univision .tve-purchase-deportes .program--icon-lock,
body.tve-auth-univision .tve-purchase-fusion .program--icon-lock,
body.tve-auth-galavision .tve-purchase-univision .program--icon-lock,
body.tve-auth-galavision .tve-purchase-deportes .program--icon-lock,
body.tve-auth-galavision .tve-purchase-fusion .program--icon-lock,
body.tve-auth-deportes .tve-purchase-galavision .program--icon-lock,
body.tve-auth-deportes .tve-purchase-univision .program--icon-lock,
body.tve-auth-deportes .tve-purchase-fusion .program--icon-lock,
body.tve-auth-fusion .tve-purchase-galavision .program--icon-lock,
body.tve-auth-fusion .tve-purchase-univision .program--icon-lock,
body.tve-auth-fusion .tve-purchase-deportes .program--icon-lock,
body.tve-auth-univision .purchase-type-3 .program--icon-lock,
body.tve-auth-galavision .purchase-type-3 .program--icon-lock,
body.tve-auth-deportes .purchase-type-3 .program--icon-lock,
body.tve-auth-fusion .purchase-type-3 .program--icon-lock,
body.isMVPDLoggedIn .purchase-type-1 .program--icon-lock,
body.isMVPDLoggedIn .purchase-type-2 .program--icon-lock,
body.isMVPDLoggedIn .purchase-type-3 .program--icon-lock,
.channel-neu-noaccess .program--icon-lock
{
	display: block;
}

body.isAnonymous.isFreePreview .purchase-type-1 .program--icon-lock,
body.isAnonymous.isFreePreview .purchase-type-2 .program--icon-lock,
body.isAnonymous.isFreePreview .purchase-type-3 .program--icon-lock,
body.isAnonymous.isFreePreview .channel-neu-noaccess .program--icon-lock,
body.isDTCUser .purchase-type-1 .program--icon-lock,
body.isDTCUser .purchase-type-3 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-univision .purchase-type-1 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-galavision .purchase-type-1 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-deportes .purchase-type-1 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-fusion .purchase-type-1 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-univision .purchase-type-2 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-galavision .purchase-type-2 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-deportes .purchase-type-2 .program--icon-lock,
body.isMVPDLoggedIn.tve-auth-fusion .purchase-type-2 .program--icon-lock,
body.tve-auth-univision .tve-purchase-univision .program--icon-lock,
body.tve-auth-galavision .tve-purchase-galavision .program--icon-lock,
body.tve-auth-deportes .tve-purchase-deportes .program--icon-lock,
body.tve-auth-fusion .tve-purchase-fusion .program--icon-lock
{
	display: none;
}
body.isVIP .program--icon-lock
{
	display: none!important;
}
body.isTemporaryUser .program--icon-lock
{
	display: none !important;
}
.program__stats a
{
	font-weight: 400;
	color: #b70090;
	font-size: 16px;
}
.program__desc
{
	font-size: 16px;
}
.no-items-text
{
	display: block;
	font-weight: 600;
	font-size: 16px
}
@media (max-width: 1199px)
{

}
@media (max-width: 991px)
{

}
@media (max-width: 767px)
{
	.program__content
	{
		display: none;
		width: 100%;
	}
	.is-open .program__content
	{
		height: 100%;
	}
	.program--center
	{
		text-align: center;
	}
	.program__title
	{
		font-size: 20px;
	}
	.program__title--size-small
	{
		font-size: 16px;
	}
	.program__img--margin
	{
		margin: auto;
	}
	.program__img--small
	{
		width: 100%;
		height: 100%;
	}
	.overlayComponent .program__img--small{
		height: auto;
	}
	.program__info--margin
	{
		margin: 5px 0 15px;
	}
	.program__info--size-bigger
	{
		font-size: 12px;
	}
	.program__desc
	{
		margin-top: 18px;
	}
}
@media (min-width: 768px)
{
	.program--margin
	{
		margin-left: -30px;
	}
	.program__detail
	{
		display: inline-block;
	}
	.program__img-wrap--position
	{
		float: left;
	}
	.program__img-wrap--margin
	{
		margin: 0 30px;
	}
	.program__img--small
	{
		width: 204px;
		height: 116px;
	}
}
/* HOME PAGE: WAHT'S NEW */
.whats-channle-logo-wrap
{
	padding-right: 20px;
}
.whats-program__info--margin
{
	margin-top: -5px;
}
@media (max-width: 767px)
{
	.whats-program__title
	{
		font-size: 16px;
	}
	.program__detail
	{
		padding-right: 15px;
	}
}
@media(max-width:483px){
	.whats-program__title
	{
		font-size: 12px;
	}
	.program__detail
	{
		line-height:14px;
		padding-right: 5px;
	}
	.whats-program__info--margin
	{
		margin-top: 0;
	}
}
/* CHANNELS */
.channel-program
{
	display: table;
}
.channle-logo-wrap
{
	padding-right: 20px;
}
.channle-logo-wrap--table
{
	display: table-cell;
	vertical-align: middle;
	width: 80px;
}
.channel-logo
{
	max-width: 60px;
	min-width: 40px;
}
.channel-program__detail
{
	width: calc(100% - 288px);
}
.channel-program__detail--margin
{
	padding-left: 30px;
}
.program__header-wrap
{
	height: 30px;
	font-weight: 800;
	font-size: 16px;
}
@media (max-width: 767px)
{
	.channle-logo-wrap
	{
		padding-right: 15px;
	}
	.channel-program__detail
	{
		width: calc(100% - 170px);
		padding-left: 15px;
		line-height: 18px;
	}
	.channel-program__img--small
	{
		width: 112px;
	}
	.channle-program__title
	{
		font-size: 16px;
	}
}
/* SHOW PROGRAMS */

@media (min-width: 768px)
{
	.show-program
	{
		height: 116px;
	}
	.show-program .program__detail
	{
		width: calc(100% - 264px);
	}
}
/* Program End */
/* Packages Start */
/*new package screen start*/
.pack
{
	padding-top: 80px;
}
.pack__message
{
	text-align: center;
	font-weight: 400;
	font-size: 30px;
	line-height: 42px;
	color: #fff;
	margin-bottom: 50px;
}
.pack__head
{
	display: flex;
	justify-content: space-between;
	color: #eee;
	border-bottom: 5px solid #313335;
	padding-bottom: 15px;
	min-height: 52px;
}
.pack__head.is-monthly .monthly
{
	display: inline-block;
}
.pack__head.is-annual .annual
{
	display: inline-block;
}
.pack__head-title
{
	display: none;
	font-size: 26px;
	font-weight: 700;
}
.pack__head-price
{
	display: none;
	font-size: 24px;
	font-weight: 500;
}
.pack__head-price.annual
{
	color: #00c473;
}
.desc-item
{
	font-weight: 400;
	font-size: 18px;
	line-height: 48px;
}
.desc-item-dot
{
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	border: 2px solid #a0aab3;
	margin-right: 25px;
}
.pack__body
{
	padding-top: 50px;
}
.pack__footer
{
	text-align: center;
	display: flex;
	align-items: flex-end;
	justify-content: space-around;
	margin-top: 30px;
}
.pack__footer-signin
{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.pack-btn
{
	width: 255px;
	margin: 30px 0 0;
	text-transform: uppercase;
}
.pack-btn-desc
{
	font-weight: 700;
	font-size: 12px;
	line-height: 30px;
	color: #fff;
}
.pack-btn-signin
{
	background-color: transparent;
	border-radius: 5px;
	border: 1px solid rgb(128, 128, 128);
	margin-top: 0;
	color: #a0aab3;
	height: 50px;
}
.pack-btn-signin:hover
{
	background-color: #fff;
	color: #000;
	opacity: 1;
}
@media (max-width: 991px)
{
	.pack
	{
		padding-top: 60px;
	}
	.pack__message
	{
		font-size: 26px;
		line-height: 32px;
	}
	.desc-item
	{
		line-height: 36px;
		margin-bottom: 22px;
	}
}
@media (max-width: 767px)
{
	.pack
	{
		padding-top: 60px;
	}
	.pack__head-title
	{
		font-size: 24px;
	}
	.desc-item
	{
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 22px;
		display: flex;
		align-items: baseline;
	}
	.pack__footer
	{
		flex-direction: column;
		align-items: center;
	}

	.pack__footer-signin
	{
		margin-top: 30px;
	}
	.desc-item-text {
		display: inline-block;
		max-width: -moz-calc(100% - 38px); /* Firefox */
		max-width: -webkit-calc(100% - 38px); /* WebKit */
		max-width: calc(100% - 38px); /* Standard */
	}
}
@media (max-width: 483px)
{
	.pack
	{
		padding: 30px 0px 35px;
	}
	.desc-item
	{
		font-weight: 300;
		line-height: 24px;
	}
	.pack__body {
		padding: 50px 20px 0;
	}
}
@media (max-width: 425px) {

	.pack__head-title, .pack__head-price {
		font-size: 20px;
	}
}

/*new package screen end*/
/* Packages End */

/*player count down start*/
.countdown
{
	margin: 0;
	padding: 15px 25px;
	background: -webkit-linear-gradient(top, #000, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.33));
	/*Safari 5.1-6*/
	background: -o-linear-gradient(top, #000000, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
	/*Opera 11.1-12*/
	background: -moz-linear-gradient(top, #000000, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
	/*Fx 3.6-15*/
	background: linear-gradient(to top, #000, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.38));
	/*Standard*/
	box-shadow: 0px -5px 5px 5px rgba(0, 0, 0, 0.72);
}
.countdown .cancel a
{
	color: #ffffff;
	font-weight: 600;
}
.countdown .program__img-wrap.program__img-wrap--table
{
	width: 104px;
}
.countdown .program__detail a
{
	cursor: default;
}
.countdown .program__detail a:hover
{
	text-decoration: none;
}
@media (max-width: 1023px)
{
	.countdown
	{
		padding: 15px 15px;
	}
}
@media (max-width: 767px)
{
	.countdown
	{
		padding: 15px 10px;
	}
}
/*player count down end*/
