/* reset css */
body
{
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	font-size: 14px;
	color: #a0aab3;
	background-color: #0c0c0c;
	overflow-x: hidden;
}
body.is-overlay-open,
body.is-menu-open
{
	overflow: hidden;
	height: 100vh;
	padding-bottom: constant(safe-area-inset-bottom);
}
/* Fixed mobile header__menu scroll bug*/
body.is-menu-open{
	position:fixed;
	width:100%;
}
/* Fixed ipad scroll bug*/
body.is-overlay-open{
	position:fixed;
	width:100%;
}
/* Fixed ios11 input bug */
body.is-overlay-open.is-signin-open
{
	position: fixed;
	width: 100%;
}
body.is-menu-open ::-webkit-scrollbar,
body.is-overlay-open ::-webkit-scrollbar
{
	display: none;
}
input, textarea, select
{
	font-size: 100%;
	font-family: inherit;
}
body, h1, h2, h3, h4, h5, h6, p, ul, ol, form
{
	margin: 0;
}
h1, h2, h3, h4, h5, h6
{
	font-weight: normal;
}
ul, ol
{
	padding-left: 0;
	list-style-type: none;
}
img
{
	border: 0;
}
a
{
	cursor: pointer;
	text-decoration: none;
}
.owl-carousel.owl-drag .owl-item
{
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}
/*Common Loading Start*/
.loading
{
	display: none;
	position: relative;
	font-size: 10px;
	width: 100px;
	height: 100px;
	margin: 20px auto;
	border-radius: 50%;
	background: -moz-linear-gradient(left, #696969 10%, rgba(169, 169, 169, 0) 42%);
	background: -webkit-linear-gradient(left, #696969 10%, rgba(169, 169, 169, 0) 42%);
	background: -o-linear-gradient(left, #696969 10%, rgba(169, 169, 169, 0) 42%);
	background: -ms-linear-gradient(left, #696969 10%, rgba(169, 169, 169, 0) 42%);
	background: linear-gradient(to right, #696969 10%, rgba(169, 169, 169, 0) 42%);
	-webkit-animation: loading 1.4s infinite linear;
	-o-animation: loading 1.4s infinite linear;
	-moz-animation: loading 1.4s infinite linear;
	animation: loading 1.4s infinite linear;
}
.loading:before
{
	width: 50%;
	height: 50%;
	background: #696969;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.loading:after
{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #0c0c0c;
	width: 80%;
	height: 80%;
	border-radius: 50%;
	margin: auto;
}
@keyframes loading
{
	0%
	{
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100%
	{
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes loading
{
	0%
	{
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100%
	{
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes loading
{
	0%
	{
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100%
	{
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-ms-keyframes loading
{
	0%
	{
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100%
	{
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-o-keyframes loading
{
	0%
	{
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100%
	{
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/*Common Loading End*/
.btn
{
	font-weight: 700;
	display: inline-block;
	line-height: 50px;
	border-radius: 4px;
	min-width: 150px;
	text-align: center;
	padding: 0 10px;
	box-sizing: border-box;
}
.btn__small
{
	height: 36px;
	line-height: 36px;
	min-width: 142px;
}
.btn-big
{
	width: 100%;
}
.btn--primary
{
	color: #fff;
	background: rgba(0, 196, 115, 1);
}
.btn:hover
{
	opacity: 0.8;
}
.mask
{
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	z-index: -1;
	opacity: 0;
	transition: all .56s;
}
.mask.header-custom-mask
{
	display: none;
	-webkit-animation: maskFadeIn .56s;
	-o-animation: maskFadeIn .56s;
	animation: maskFadeIn .56s;
	animation-fill-mode: forwards;
}
.mask.is-open.header-custom-mask
{
	display: block;
	-webkit-animation: maskFadeIn .56s;
	-o-animation: maskFadeIn .56s;
	animation: maskFadeIn .56s;
}
@keyframes maskFadeOut
{
	0%
	{
		opacity: 1;
	}
	100%
	{
		opacity: 0;
		display: none;
	}
}
@keyframes maskFadeIn
{
	0%
	{
		opacity: 0;
	}
	100%
	{
		opacity: 1;
	}
}
.mask.is-open
{
	z-index: 11;
	opacity: 1;
}
/* Sign In Start */
.overlay
{
	position: fixed;
	background: rgba(0, 0, 0, .9);
	width: 100vw;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	transition: all 0.2s;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.is-pin-overlay .overlay
{
	position: absolute;
}
.overlay.is-overlay-open
{
	z-index: 10002;
	opacity: 1;
}
.overlay-player.is-overlay-open
{
	z-index: 10001;
}
.overlay-player .modal-wrap
{
	display: none;
}
.overlay-player.is-overlay-open .modal-wrap
{
	display: block;
}
.js-modal-wrap-player
{
	position: relative;
	width: 100%;
	min-height: 100%;
}
.modal
{
	position: absolute;
	left: 50%;
	top: 50%;
	width: 620px;
	background: #eee;
	display: none;
}
.modal-all-signin
{
	width: 606px;
}
.modal.is-open
{
	display: block;
}
.modal.is-open-custom
{
	display: block;
	opacity: 0;
}
.modal__title
{
	padding: 0 30px;
	font-weight: 600;
	line-height: 60px;
	border-bottom: 1px solid #e1e1e1;
	color: #0c0c0c;
	text-transform: uppercase;
}
.modal__icon--close,
.modal__icon--close::before,
.modal__icon--close::after
{
	height: 2px;
	width: 18px;
	background: rgba(12, 12, 12, 1);
	border-radius: 1px;
	cursor: pointer;
	display: inline-block;
	transition: 0.2s linear;
}
.modal__icon--close
{
	position: absolute;
	right: 28px;
	top: 20px;
	height: 18px;
	width: 18px;
	background: rgba(12, 12, 12, 0);
}
.modal__icon--close::before
{
	position: absolute;
	top: 9px;
	left: 0;
	content: "";
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}
.modal__icon--close::after
{
	position: absolute;
	top: 9px;
	left: 0;
	content: "";
	transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
}
.modal-content
{
	line-height: 24px;
	color: #0c0c0c;
}
.modal-content--table
{
	text-align: center;
	padding: 0 !important;
}
.modal-content__layout
{
	padding: 30px;
}
.modal__icon
{
	width: 60px;
	height: 60px;
	display: inline-block;
	margin-top: 20px;
}
.modal__icon--lock
{
	background: url(../images/login_icon_lock.svg) no-repeat;
}
.modal__icon--mvpd
{
	background: url(../images/login_icon_tv.svg) no-repeat;
}
.modal__name
{
	margin-top: 24px;
	font-weight: 700;
	font-size: 18px;
}
.modal__name--big
{
	font-size: 24px;
	color: #000;
	font-weight: 700;
}
.modal__name--small
{
	font-size: 16px;
}
.modal__desc--small
{
	font-size: 12px;
	font-weight: 600;
	color: #a0aab3;
}
.modal__desc--margin
{
	margin-top: 26px;
}
.modal-content__neu .modal__desc--margin
{
	margin-top: 50px;
}
.modal-content .btn--sign
{
	width: 100%;
	margin-top: 86px;
}
.modal-wrap
{
	position: relative;
	width: 100%;
	min-height: 100%;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-khtml-user-select: none;
	user-select: none;
}
.modal-signin
{
	height: 486px;
	background-color:#161616;
}
.modal-signin .modal__icon--close
{
	top: 115px;
}
.modal-signin .modal__icon--close::before,
.modal-signin .modal__icon--close::after
{
	background-color: #fff;
}
.modal-mvpd
{
	height: 439px;
	background: none;
}
.modal-content__lock
{
	padding: 35px 30px 2px;
	border-bottom: 1px solid #e1e1e1;
	background: #eee;
}
.modal-content__lock--nofooter
{
	padding-bottom: 45px;
}
.modal__item
{
	border-bottom: 1px solid #e1e1e1;
	padding: 28px 30px;
	display: table;
	width: 100%;
	background: #fff;
}
.modal__item-info
{
	display: table-cell;
	vertical-align: middle;
	width: calc(100% - 120px);
	padding-right: 20px;
}
.modal__item-title
{
	font-size: 16px;
	font-weight: 700;
	color: #000;
	line-height: 18px;
}
.modal__item-desc
{
	font-size: 12px;
	color: #a0aab3;
	line-height: 18px;
	margin-top: 2px;
}
.modal__item .modal__btn-wrap
{
	display: table-cell;
	vertical-align: middle;
	text-align: right;
}
.modal__footer
{
	height: 69px;
	line-height: 69px;
	text-align: center;
}
.modal__link
{
	color: #00c473;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
}
.modal__link:hover
{
	text-decoration: underline;
}
/* iPhone X in landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape)
{
	.modal-signin
	{
		width: 620px!important;
	}
}
/* Todo parental controls start*/
.modal-parental-controls .modal-item-title
{
	color: #0c0c0c;
}
.modal-parental-controls .modal__item-desc
{
	font-size: 14px;
	line-height: 24px;
	font-weight: 300;
	color: #03050b;
}
.modal-parental-controls .modal-content
{
	margin: 10px 30px 0 30px;
}
.modal-parental-controls .modal__link
{
	text-transform: none;
	font-weight: 400;
	line-height: 18px;
}
.modal__form
{
	text-align: center;
	margin-top: 30px;
}
.input-pin-item
{
	width: 80px;
	height: 80px;
	line-height: 36px;
	font-size: 36px;
	padding:22px 0;
	font-weight: 800;
	text-align: center;
	border: 2px solid #ccc;
	border-radius: 3px;
	outline: none;
}
.input-pin-item:not(:last-child)
{
	margin-right: 15px;
}
input.input-pin-item:focus
{
	outline: none !important;
	border: 2px solid #00c473;
	box-shadow: 0 0 5px #ccc;
}
#js-pc-form .labelErrorDesc
{
	color: #fe0000;
	opacity: 0;
}
#js-pc-form .labelErrorDesc.visibility-visible
{
	opacity: 1;
}
@media (max-width:767px){
	.input-pin-item
	{
		width: 50px;
		height: 50px;
		line-height: 24px;
		font-size: 24px;
		padding: 13px 0;
	}
}

/*Todo parental controls end*/
/*Account Hold Start*/
.modal.modal-alert
{
	background-color: #0c0c0c;
}
.modal-alert-close .modal__icon--close:before,
.modal-alert-close .modal__icon--close:after
{
	background: #fff;
}
.modal-alert .modal-alert-content
{
	text-align: center;
	padding: 120px 85px;
}
.modal-alert .modal-alert-content .alert__title
{
	font-weight: 600;
	font-size: 66px;
	color: #fff;
}
.modal-alert .modal-alert-content .alert__title-outline
{
	display: inline-block;
	height: 2px;
	width: 100%;
	background-image: linear-gradient(to left, #00c473, #1d1dea, #c626b6, #ed1c24);
}
.modal-alert .modal-alert-content .alert__title-outline.outline-gray{
	background:#1e1e1e;
}
.modal-alert .modal-alert-content .alert__subtitle
{
	font-weight: 200;
	font-size: 27px;
	color: #fbfdfc;
	line-height: 50px;
}
.modal-alert-temporary.modal{
	z-index: 10003;
	width:800px;
}
.modal-alert-temporary.modal-alert .modal-alert-content{
	padding:60px 74px;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__subtitle{
	font-weight:600;
	font-size:24px;
	line-height:24px;
	margin-top:10px;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__desc
{
	font-size:14px;
	text-align:center;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__desc a
{
	font-weight:600;
	color:#00C473;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__desc a:hover
{
	text-decoration: underline;
}
.modal-alert .modal-alert-content .alert__button
{
	width: 255px;
	margin: 15px 0 10px;
	text-transform: uppercase;
	color: #fff;
	background: rgba(0, 196, 115, 1);
	font-weight: 700;
	display: inline-block;
	line-height: 50px;
	border-radius: 4px;
	min-width: 150px;
	text-align: center;
	padding: 0 10px;
	box-sizing: border-box;
}
.modal-alert .modal-alert-content .alert__button:hover
{
	opacity: 0.8;
}
.modal-alert .modal-alert-content .alert__desc
{
	font-weight: 300;
	font-size: 15px;
	color: #a0aab3;
	text-align: left;
	line-height: 25px;
	margin-top: 10px;
}
.modal-alert .modal-alert-content .alert__note{
	font-weight: 300;
	font-size: 13px;
	color: #a0aab3;
	text-align: left;
	line-height: 20px;
	margin-top: 8px;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__note{
	text-align:center;
	color:#fff;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__note .alert__footer-title{
	font-weight:600;
	text-transform: uppercase;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__note .alert__footer-link a{
	font-weight:600;
	color:#00C473;
	text-transform: uppercase;
}
.modal-alert-temporary.modal-alert .modal-alert-content .alert__note .alert__footer-link a:hover{
	text-decoration: underline;
}
@media (max-width: 767px)
{
	.modal.modal-alert
	{
		height: 350px;
	}
	.modal-alert-temporary.modal.modal-alert{
		height: 350px;
		width:100%;
	}

	.modal-alert-temporary.modal-alert .modal-alert-content
	{
		padding: 60px 60px;
	}
	.modal-alert .modal-alert-content
	{
		padding: 80px 60px;
	}
	.modal-alert .modal-alert-content .alert__subtitle
	{
		line-height: 30px;
		margin-top: 10px;
	}
}
@media (max-width: 483px)
{
	.modal-alert .modal-alert-content
	{
		padding: 50px 35px;
	}
	.modal-alert-temporary.modal-alert .modal-alert-content
	{
		padding: 40px 20px;
	}
	.modal-alert .modal-alert-content .alert__title
	{
		font-size: 46px;
	}
	.modal-alert .modal-alert-content .alert__subtitle
	{
		font-size: 18px;
		line-height: 22px;
	}
	.modal-alert .modal-alert-content .alert__desc
	{
		font-size: 12px;
		line-height: 18px;
	}
	.modal-alert .modal-alert-content .alert__note
	{
		font-size: 10px;
		line-height: 16px;
	}
	.modal-alert-temporary.modal-alert .modal-alert-content .alert__button
	{
		width:100%;
	}
}
/*Account Hold End*/
/*Provicy Policy Update Notice Start*/
.modal-alert-update.modal-alert
{
	width: 760px;
	height: auto;
}
.modal-alert-update.modal-alert .modal-alert-content
{
	text-align: left;
	padding: 40px 85px;
}
.modal-alert-update.modal-alert .modal-alert-content .alert__title
{
	font-size: 32px;
}
.modal-alert-update.modal-alert .modal-alert-content .alert__desc
{
	font-size: 18px;
	font-weight: 300;
}
.modal-alert-update.modal-alert .modal-alert-content .alert__desc a
{
	color:#00C473;
	font-weight: 700;
}
.modal-alert-update.modal-alert .modal-alert-content .alert__button
{
	width: 255px;
	margin: 15px 0 10px;
	text-transform: uppercase;
	color: #fff;
	background: rgba(0, 196, 115, 1);
	font-weight: 800;
	display: inline-block;
	line-height: 50px;
	border-radius: 4px;
	min-width: 150px;
	text-align: center;
	padding: 0 10px;
	box-sizing: border-box;
}
/*Provicy Policy Update Notice End*/
/*Program not authorized to mvpd Start*/
.modal-alert.modal-alert-programnotauthorized
{
	width: 768px;
}
.modal-alert.modal-alert-programnotauthorized .modal-alert-content
{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 40px 50px;
}
.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert-desc
{
	font-size: 30px;
	text-align: left;
}
.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert__button-more
{
	margin-left: 30px;
}
@media (max-width: 767px)
{
	.modal-alert.modal-alert-programnotauthorized
	{
		width: 100%;
		height: auto;
	}
	.modal-alert-update.modal-alert
	{
		width: 100%;
	}
	.modal-alert.modal-alert-update .modal-alert-content{
		padding:40px 35px;
	}
	.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert-desc{
		font-size:24px;
	}
	.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert__button
	{
		width: 160px;
		line-height:45px;
	}
}
@media (max-width: 483px)
{
	.modal-alert.modal-alert-programnotauthorized .modal-alert-content{
		padding:30px 15px;
	}
	.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert-desc{
		font-size:16px;
	}
	.modal-alert .modal-alert-content .alert__button
	{
		width: 100%;
	}
	.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert__button{
		min-width:auto;
		width:120px;
		line-height:40px;
	}
	.modal-alert.modal-alert-programnotauthorized .modal-alert-content .alert__button-more
	{
		margin-left: 20px;
	}
}
/*Program not authorized to mvpd End*/
/*tvepicker modal Start*/
.modal-tvepicker .modal__icon--close
{
	top: 15px;
	right: 10px;
}
.modal-tvepicker .modal__icon--close::before,
.modal-tvepicker .modal__icon--close::after
{
	background: rgba(255, 255, 255, 1);
}
.modal__content-wrap
{
	text-align: center;
	margin: 15px 30px;
}
.picker-mvpd-logo
{
	margin: 20px 0;
}
.picker-mvpd-title
{
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	padding: 0 10px;
}
.picker-mvpd-subtitle
{
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	margin-top: 12px;
}
.picker-support-link {
	margin-top: 5px;
}
.picker-support-link a {
	color: #00c473;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}
.picker-support-link a.link-web {
	display: none;
}
.picker-support-link a.link-mobile {
	display: none;
}

.picker-mvpd-content
{
	padding-top: 20px;
}
#mvpdList.mvpd-list
{
	margin-left: -5px;
}
#mvpdList.mvpd-list .mvpdProvider
{
	width: 105px;
	height: 55px;
	display: inline-flex;
	margin-left: 5px;
	margin-bottom: 5px;
	text-align: center;
	background-color: #353535;
	box-sizing: border-box;
	border: none;
	border-radius: 0px;
}
#mvpdList.mvpd-list .mvpdProvider img
{
	width: 100%;
	height: auto;
}
.picker-subscribe
{
	padding-bottom: 15px;
}
.picker-signin
{
	padding-top: 15px;
	margin: 0 65px;
	border-top: 1px solid #464646;
}
.picker-subscribe-title,
.picker-signin-title
{
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
}
.picker-subscribe-btn,
.picker-signin-btn
{
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	color: #00c473;
}
/*Fix scroll bar bug when image load slowly*/
@media (min-width: 620px)
{
	#mvpdList.mvpd-list
	{
		min-width: 550px;
	}
}
.freesample-end {
	display: none;
}
.hasFreeSampleEnded .freesample-end {
	display: block;
	position: absolute;
	top: 0;
	height: 45px;
	width: 100%;
	line-height: 43px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	background: #363636;
	border-top: 2px solid #636363;
	border-bottom: 2px solid #636363;
}
.hasFreeSampleEnded .modal__content-wrap {
	margin: 40px 30px;
}
@media (max-width: 483px) {
	.hasFreeSampleEnded .freesample-end {
		font-size: 14px;
	}
}
/*tvepicker modal End*/
.modal-player
{
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
	background: transparent;
}
.modal.modal-player.is-open
{
	animation: none;
	-webkit-animation: none;
}
#signInOptionsFrame
{
	width: 100% !important;
	height: 100% !important;
	overflow: hidden;
}
@keyframes modal-enter-right-left
{
	from
	{
		opacity: 0;
		left: 100%;
	}
	to
	{
		opacity: 1;
		left: 0;
	}
}
@keyframes modal-exit-right-left
{
	from
	{
		opacity: 1;
		left: 0;
	}
	to
	{
		opacity: 0;
		left: -100%;
	}
}
@keyframes modal-enter-bottom-top
{
	from
	{
		opacity: 0;
		transform: translate(-50%, -20%);
		-webkit-transform: translate(-50%, -20%);
	}
	to
	{
		opacity: 1;
	}
}
@keyframes modal-exit-bottom-top
{
	from
	{
		opacity: 1;
	}
	to
	{
		opacity: 0;
		transform: translate(-50%, -80%);
		-webkit-transform: translate(-50%, -80%);
	}
}
@media (max-width: 767px)
{
	body
	{
		-webkit-text-size-adjust: 100%;
	}
	.modal
	{
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 0;
		width: 100%;
	}
	.modal__item-info
	{
		display: block;
	}
	.modal__item-info
	{
		display: block;
		width: 100%;
	}
	.modal__item .modal__btn-wrap
	{
		display: block;
		margin-top: 8px;
		text-align: left;
	}
	.modal.is-open
	{
		animation: modal-enter-right-left .5s;
		-webkit-animation: modal-enter-right-left .5s;
	}
	.modal.is-hidden
	{
		animation: modal-exit-right-left .5s;
		-webkit-animation: modal-exit-right-left .5s;
		animation-fill-mode:forwards;
		-webkit-animation-fill-mode:forwards;
	}
	.modal__name
	{
		margin-top: 4px;
	}
	.modal-all-signin .modal__desc--margin
	{
		margin-top: 0;
	}
	.modal-content .btn--sign
	{
		margin-top: 8px;
	}
	.modal-content__neu
	{
		padding-top: 5px;
	}
	.modal-content__mvpd
	{
		padding-top: 10px;
	}
}
@media (max-width: 483px)
{
	body
	{
		-webkit-text-size-adjust: 100%;
	}
	.modal-signin
	{
		height: 600px;
	}
	.modal-mvpd
	{
		height: 729px;
	}
}
@media (min-width: 768px)
{
	.modal
	{
		transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}
	.modal.modal-player
	{
		transform: none;
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		-o-transform: none;
	}
	.modal-content--table
	{
		display: table;
	}
	.modal-content__layout
	{
		display: table-cell;
		width: 50%;
	}
	.modal-content__neu
	{
		border-right: 1px solid #e1e1e1;
	}
	.modal.is-open
	{
		animation: modal-enter-bottom-top .5s;
		-webkit-animation: modal-enter-bottom-top .5s;
	}
	.modal.is-hidden
	{
		animation: modal-exit-bottom-top .5s;
		-webkit-animation: modal-exit-bottom-top .5s;
		animation-fill-mode:forwards;
		-webkit-animation-fill-mode:forwards;
	}
}
@media (min-width: 1024px)
{
	.modal-wrap
	{
		overflow: hidden;
	}
}
/* Sign In End */
/* Header Start */
@keyframes sticky-header
{
	from
	{
		top: -100%;
	}
	to
	{
		top: 0;
	}
}
body.is-menu-open .header
{
	position: fixed;
	width: 100%;
	z-index: 100;
	-webkit-overflow-scrolling: touch;
}
header
{
	height: 100px;
	line-height: 50px;
	padding: 25px 0;
	background: #0c0c0c;
}
.logo
{
	float: left;
	width: 160px;
}
.logo .logo__img
{
	width: 100%;
}
.header__noaccess
{
	float: right;
}
.header__noaccess.is-hidden
{
	display: none;
}
.header__noaccess a
{
	text-transform: uppercase;
}
.header__link
{
	font-weight: 700;
	color: #eee;
	padding: 0 5px;
	margin: 0 8px;
	transition: all .25s ease-in-out;
	display: block;
}
.header__text
{
	text-transform: uppercase;
}
.header__link--signin
{
	padding: 0;
	margin: 0;
}
.header-btn
{
	margin-left: 28px;
	width: 230px;
}
.header__noaccess .header-btn
{
	background-color: transparent;
	color: #fff;
	font-weight: 700;
	border-radius: 5px;
	border: 1px solid rgb(128, 128, 128);
	height: 50px;
	float: left;
}
.header__noaccess .header-btn:hover
{
	background-color: #fff;
	color: #000;
	opacity: 1;
}
.header--small
{
	height: 80px;
	line-height: 36px;
	padding: 22px 0;
	position: relative;
}
.header--small .logo
{
	width: 116px;
	height: 36px;
	margin-right: 16px;
}
.header__menu
{
	float: left;
}
.header__menu.is-hidden
{
	display: none;
}
.header__item
{
	position: relative;
}
.header__line
{
	position: absolute;
	left: 50%;
	top: 38px;
	transform: translateX(-50%);
	width: 30px;
	height: 2px;
	/*background: #53b373;*/
	background: #00c473;
	opacity: 0;
	transition: all 0.2s;
}
.header__icon-down
{
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #7d7d7d;
	display: inline-block;
	vertical-align: middle;
	margin-left: 4px;
	margin-top: -1px;
}
.header--more
{
	width: 23px;
	height: 5px;
	background: url("../images/header_more.svg") no-repeat;
	display: inline-block;
	vertical-align: middle;
	margin-top: -1px;
}
.header__dropdown
{
	transition: all .25s ease-in-out;
	line-height: 30px;
}
.header__icon-up
{
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #00c473;
	display: block;
	margin-left: 20px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-bottom: -1px;
}
.header__dropdown-content
{
	font-weight: 400;
}
.header__dropdown-title
{
	float: left;
	padding-left: 18px;
	padding-top: 18px;
	width: 150px;
	box-sizing: border-box;
}
.header__dropdown-title .header__dropdown-link
{
	font-weight: 600;
}
.header__item:hover .header__link,
.header__item.is-selected .header__link
{
	color: #00c473;
}
.header__item:hover .header--more
{
	background: url("../images/header_more_hov.svg") no-repeat;
}
.header__dropdown-link:hover,
.header__dropdown-link.is-selected
{
	font-weight: 600;
	color: #00c473;
}
.header__right
{
	float: right;
	display: flex;
	align-items: center;
	height: 36px;
}
.header__right.is-hidden
{
	display: none;
}
.header__icon
{
	margin-left: 20px;
	cursor: pointer;
}
.header__personal
{
	display: none;
}
body.enablePs .header__personal
{
	display: inline-block;
	width: 20px;
	height: 14px;
	background: url("../images/header_personal.svg") no-repeat;
	vertical-align: middle;
}

body.enablePs .header__personal:hover
{
	background: url("../images/header_personal_hov.svg") no-repeat;
}

.header__dropdown-item .header__personal-text,
.header__menu .header__personal-text
{
	display: none;
}

body.enablePs .header__dropdown-item .header__personal-text,
body.enablePs .header__menu .header__personal-text
{
	display: block;
}
.header__mvpdlogo-link.disabled
{
	pointer-events: none;
}
img.header__mvpdlogo
{
	height: 20px;
}
.header__myaccount
{
	display: inline-block;
	vertical-align: middle;
	position: relative;
	color: #0c0c0c;
}
.header__user-icon
{
	width: 20px;
	height: 20px;
	display: block;
	background: url("../images/header_user.svg") no-repeat;
}
.header__user-icon:hover
{
	background: url("../images/header_user_hov.svg") no-repeat;
}
.header__myaccount .header__dropdown
{
	width: 300px;
	left: -220px;
	top: 20px;
}
.header__myaccount .header__icon-up
{
	margin-left: 222px;
}
.header__myaccount .header__dropdown.is-open
{
	max-height: 310px;
}
.header__myaccount .header__dropdown-item
{
	float: none;
	border-bottom: 1px solid #464646;
	padding: 10px 20px;
}
.header__myaccount .header__dropdown-text
{
	color: #fff
}
.header__dropdown-item:hover .header__dropdown-text
{
	color: #00c473;
}
.header__dropdown-item--default
{
	cursor: default;
}
.header__myaccount-title
{
	color: #a0aab3;
	font-weight: 700;
	font-size: 12px;
	text-transform: uppercase;
	cursor: default;
}
.header__myaccount-text
{
	color: #fff;
}
.header__myaccount-text:hover
{
	color: #00c473;
}
.header__myaccount-text--block
{
	display: block;
}
.header__myaccount-text--block.is-hidden
{
	display: none;
}
.header__myaccount-neu,
.header__myaccount-mvpd
{
	display: none;
}
.header__myaccount-mvpd .mvpd-icon-pc
{
	display: inline-block;
}
.header__myaccount-mvpd .mvpd-icon-mobile
{
	display: none;
}
.isLogin .header__myaccount-neu,
.isVIP .header__myaccount-neu,
.isDTCUser .header__myaccount-neu,
.isMVPDLoggedIn .header__myaccount-mvpd
{
	display: block;
}
.header__myaccount-neu-login
{
	display: block;
}
.header__myaccount-mvpd.header__myaccount-mvpd-loading
{
	color: #00c47c;
	font-size: 14px;
	font-weight: 400;
}
.header__myaccount-mvpd.header__myaccount-mvpd-login,
.header__myaccount-mvpd.header__myaccount-mvpd-logout,
.isLatestMVPD .header__myaccount-mvpd.header__myaccount-mvpd-loading,
.isLatestMVPD .header__myaccount-mvpd.header__myaccount-mvpd-logout,
.isLatestMVPD.isMVPDLoggedIn .header__myaccount-mvpd.header__myaccount-mvpd-login
{
	display: none;
}
.header__myaccount-mvpd.header__myaccount-mvpd-loading,
.isLatestMVPD .header__myaccount-mvpd.header__myaccount-mvpd-login,
.isLatestMVPD.isMVPDLoggedIn .header__myaccount-mvpd.header__myaccount-mvpd-logout
{
	display: block;
}

.isLogin .header__myaccount-neu-login,
.isVIP .header__myaccount-neu-login,
.isDTCUser .header__myaccount-neu-login
{
	display: none;
}
.isMVPDLoggedIn .header__mvpd-signout
{
	float: right;
	margin-top: 0px;
	color: #fff;
}
.isMVPDLoggedIn .header__mvpd-signout:hover
{
	color: #00c47c;
}
.header__email-icon
{
	width: 18px;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	background: url("../images/header_email.svg") no-repeat;
	margin-right: 9px;
}
.header__dropdown-item:hover .header__email-icon
{
	background: url("../images/header_email_hov.svg") no-repeat;
}
.header__map-icon
{
	width: 12px;
	height: 16px;
	display: inline-block;
	vertical-align: sub;
	background: url("../images/header_map.svg") no-repeat;
	margin-left: 3px;
	margin-right: 12px;
}
.header__dropdown-item:hover .header__map-icon
{
	background: url("../images/header_map_hov.svg") no-repeat;
}
.header__search
{
	display: inline-block;
	width: 16px;
	height: 16px;
	background: url("../images/header_search.svg") no-repeat;
	vertical-align: middle;
}
.header__search:hover
{
	background: url("../images/header_search_hov.svg") no-repeat;
}
.header__hamburger-wrap
{
	position: relative;
	width: 18px;
	height: 18px;
	margin-right: 4px;
	display: inline-block;
	vertical-align: middle;
}
.header__hamburger,
.header__hamburger:before,
.header__hamburger::after
{
	position: absolute;
	height: 2px;
	width: 20px;
	background: #eee;
	border-radius: 1px;
	cursor: pointer;
	display: inline-block;
	transition: 0.2s linear;
}
.header__hamburger
{
	top: 8px;
}
.header__hamburger::before
{
	position: absolute;
	top: -6px;
	left: 0;
	content: "";

}
.header__hamburger::after
{
	position: absolute;
	top: 6px;
	left: 0;
	content: "";
}
.header__hamburger-wrap.is-open .header__hamburger
{
	height: 0;
}
.header__hamburger-wrap.is-open .header__hamburger::before
{
	top: 0;
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}
.header__hamburger-wrap.is-open .header__hamburger::after
{
	top: 0;
	transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
}
.header__hamburger-wrap:hover .header__hamburger,
.header__hamburger-wrap:hover .header__hamburger::before,
.header__hamburger-wrap:hover .header__hamburger::after
{
	background: #00c473;
}
.header__dropdown
{
	max-height: 0;
	overflow: hidden;
	-webkit-transition: all .35s cubic-bezier(.17, .67, .83, .67);
	transition: all .35s cubic-bezier(.17, .67, .83, .67);
}
.header__regions
{
	display: inline-block;
	vertical-align: middle;
	position: relative;
	color: #0c0c0c;
}
.header__regions-icon
{
	width: 20px;
	height: 20px;
	display: block;
	background: url("../images/header_regions.svg") no-repeat;
}
.header__regions-icon:hover
{
	background: url("../images/header_regions_hov.svg") no-repeat;
}
.regions
{
	position: absolute;
	top: 22px;
	right: 0;
	z-index: 10;
	width: 210px;
	height: 0;
	font-weight: 400;
	overflow: hidden;
	-webkit-transition: all .35s cubic-bezier(.17, .67, .83, .67);
	transition: all .35s cubic-bezier(.17, .67, .83, .67);
}
.header__regions.is-open .regions
{
	height: 306px;
}
.regions .header__icon-up
{
	margin-left: 191px;
}
.regions__list
{
	background: #eee;
	border-top: 4px solid #00c473;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
	width: 100%;
	height: 0;
	overflow: hidden;
	transition: all .35s cubic-bezier(.17, .67, .83, .67);
	-webkit-transition: all .35s cubic-bezier(.17, .67, .83, .67);
	position: absolute;
}
.regions__list-wrap
{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}
.header__regions.is-open .regions__list
{
	height: 300px;
}
.regions__item
{
	padding: 0 20px;
	cursor: pointer;
}
.regions__item:hover,
.regions__item.is-selected
{
	color: #00c473;
	font-weight: 700;
}
.regions__item.is-selected
{
	cursor: default;
}
.header__sidebar
{
	position: absolute;
	top: 0;
	right: -100%;
	width: 298px;
	height: 0;
	overflow: hidden;
	border-left: 1px solid #252525;
	background-color: #0c0c0c;
	box-sizing: border-box;
	z-index: 9999;
	transition: all .56s cubic-bezier(0.895, 0.03, 0.685, 0.22);
	-webkit-transition: all .56s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.header__sidebar.is-open
{
	right: 0;
	height: 100vh;
}
.sidebar__tags
{
	padding: 13px 20px 20px;
	overflow: hidden;
}
.sidebar__text
{
	float: left;
	width: 128px;
	height: 40px;
	line-height: 40px;
	display: block;
	border: 1px solid #252525;
	font-weight: 600;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
	font-size: 13px;
}
.sidebar__text:first-child
{
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.sidebar__text:last-child
{
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.sidebar__text:hover
{
	opacity: 0.8;
}
.sidebar__text.is-selected
{
	color: #00c473;
	border: 1px solid #00c473;
	cursor: default;
}
.sidebar__text.is-selected:hover
{
	opacity: 1;
}
.sidebar__content
{
	position: relative;
	max-height: calc(100vh - 110px);
	overflow: hidden;
	min-height: 150px;
}
.sidebar__item .program__img-wrap
{
	margin: 0 auto;
}
.sidebar__item .program__title--size-small
{
	display: block;
	text-align: left;
	line-height: 20px;
	width: 254px;
	margin: 10px auto 0;
}
@keyframes sidebar
{
	0%
	{
		right: -99%;
	}
	100%
	{
		right: 0;
	}
}
@-moz-keyframes sidebar
{
	0%
	{
		right: -99%;
	}
	100%
	{
		right: 0;
	}
}
@-webkit-keyframes sidebar
{
	0%
	{
		right: -99%;
	}
	100%
	{
		right: 0;
	}
}
@-o-keyframes sidebar
{
	0%
	{
		right: -99%;
	}
	100%
	{
		right: 0;
	}
}
.sidebar__list-wrap
{
	position: absolute;
	top: 0;
	right: -100%;
	width: 100%;
	text-align: center;
	opacity: 0;
	max-height: calc(100vh - 110px);
}
.sidebar__list-wrap.is-selected
{
	opacity: 1;
	right: 0;
	animation: sidebar .56s cubic-bezier(0.895, 0.03, 0.685, 0.22);
	-moz-animation: sidebar .56s cubic-bezier(0.895, 0.03, 0.685, 0.22);
	-webkit-animation: sidebar .56s cubic-bezier(0.895, 0.03, 0.685, 0.22);
	-o-animation: sidebar .56s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.sidebar__list
{
	position: absolute;
	left: 0;
	right: 0;
}
.sidebar__link
{
	width: 254px;
	height: 144px;
}
.icon__close-sidebar
{
	position: relative;
	width: 28px;
	height: 28px;
	cursor: pointer;
	display: block;
	margin-left: 20px;
	margin-top: 9px;
}
.icon__close-sidebar:before,
.icon__close-sidebar:after
{
	position: absolute;
	content: "";
	display: block;
	width: 10px;
	height: 2px;
	background: #a0aab3;
}
.icon__close-sidebar:before
{
	transform: rotate(45deg);
	top: 16px;
}
.icon__close-sidebar:after
{
	transform: rotate(-45deg);
	top: 10px;
}
/* Custom iScroll bar CSS Start */
.iScrollVerticalScrollbar
{
	position: absolute;
	z-index: 9999;
	width: 4px;
	bottom: 0;
	top: 0;
	right: 11px;
	overflow: hidden;
	cursor: pointer;
}
.iScrollVerticalScrollbar.iScrollBothScrollbars
{
	bottom: 18px;
}
.iScrollIndicator
{
	position: absolute;
	background: #868686;
	border-radius: 2px;
}
.iScrollVerticalScrollbar .iScrollIndicator
{
	width: 100%;
}
#cookie_disabled{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 40px;
	line-height: 20px;
	font-size: 13px;
	text-align: center;
	animation: sticky-header .35s;
	background-color: #C626B6;
	color: white;
}
#cookie_disabled a{
	color: #00C473;
	font-weight: bold;
}
#cookie_disabled #text{
	padding: 0 35px;
}
#cookie_disabled #close{
	position: absolute;
	right: 10px;
	top: 10px;
}
#cookie_disabled #close a{
	font-size: 20px;
	color: white;
	opacity: .8;
}
#cookie_disabled #close a:hover{
	opacity: 1;
	transition: .3s;
}
@media (min-width: 768px) and (max-width: 1280px){
	#cookie_disabled{height: 60px;}
	#cookie_disabled #close{top:20px;}
}
@media (min-width: 0px) and (max-width: 767px){
	#cookie_disabled{height: 70px;}
	#cookie_disabled #text{
		font-size: 10px;
		line-height: 12px;
	}
	#cookie_disabled #close{top:25px;}
}
/* Custom iScroll bar CSS End */
@media (max-width: 767px)
{
	.header__link
	{
		margin: 0 10px;
	}
	.header-sticky
	{
		position: relative;
	}
	.header_placeholder
	{
		display: none !important;
	}
	.header__menu
	{
		position: absolute;
		top: 80px;
		background: #0c0c0c;
		z-index: 10;
		width: 100%;
		height: calc(100vh - 80px);
		height: -webkit-calc(100vh - 80px);
		height: -moz-calc(100vh - 80px);
		margin: 0 -15px;
		left: -100%;
		transition: all .2s;
		overflow-y: auto;
		line-height: 50px;
		-webkit-overflow-scrolling:touch;
	}
	.header__menu.is-open
	{
		left: 15px;
	}
	.header__item
	{
		border-top: 1px solid #181818;
		font-size: 16px;
	}
	.header__item:last-child
	{
		border-bottom: 1px solid #181818;
	}
	.header__myaccount-list .header__item:last-child
	{
		border-bottom: none;
	}
	.header__item-dropdown .header__link
	{
		font-size: 14px;
		color: #a0aab3
	}
	.header__icon-down
	{
		position: absolute;
		right: 15px;
		top: 23px;
		transition: all .6s;
	}
	.header__dropdown
	{
		line-height: 50px;
	}
	.header__dropdown-link
	{
		font-weight: 300;
		color: #eee;
		font-size: 16px;
		padding: 0 5px;
		margin: 0 10px;
		cursor: pointer;
		display: block;
	}
	.header__text--small
	{
		font-size: 14px;
		color: #a0aab3;
	}
	.header__noaccess .header__item
	{
		border-top: none;
	}
	.header__item.is-open
	{
		background-color: rgba(28, 28, 28, 1);
	}
	.header__item.is-open .header__icon-down
	{
		transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
	}
	.header__item.is-open .header__dropdown
	{
		max-height: 300px;
	}
	.header__dropdown-item
	{
		font-size: 16px;
	}
	.header__myaccount-title
	{
		font-size: 14px;
		padding: 0 5px;
		margin: 0 10px;
		cursor: pointer;
	}
	.header__myaccount-title:hover
	{
		color: #00c473;
	}
	.header__myaccount-tags
	{
		padding: 0 5px;
		margin: 0 10px;
		height: 0;
		overflow: hidden;
		-webkit-transition: all .6s cubic-bezier(0.895, 0.03, 0.685, 0.22);
		transition: all .6s cubic-bezier(0.895, 0.03, 0.685, 0.22);
	}
	.header__myaccount-text
	{
		color: #eee;
		display: block;
	}
	.header__item.is-open .header__myaccount-neu
	{
		height: 100px;
	}
	.header__item.is-open .header__myaccount-mvpd
	{
		height: 50px;
	}
	.header__myaccount-mvpd .header__mvpd-signout
	{
		color: #eee;
	}
	.header__myaccount-mvpd .mvpd-icon-pc
	{
		display: none;
	}
	.header__myaccount-mvpd .mvpd-icon-mobile
	{
		display: inline-block;
	}
	.header__dropdown-link:hover,
	.header__dropdown-link.is-selected
	{
		font-weight: 300;
	}
}
@media (min-width: 768px)
{
	.header-sticky
	{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
		transition: .3s;
	}
	.warning-sticky{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10000;
	}
	.header__dropdown
	{
		position: absolute;
		top: 32px;
		left: 14px;
		z-index: 10;
		overflow: hidden;
		width: 790px; /* Fixed Me */
	}
	.header__dropdown.header__dropdown-more
	{
		width: 220px;
		left: -3px;
	}
	.header__dropdown-content
	{
		background: #eee;
		border-top: 4px solid #00c473;
		overflow: hidden;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
	}

	.header__dropdown-myaccount .header__dropdown-content
	{
		background: #252525;
	}
	.header__dropdown-list-wrap
	{
		padding: 18px 0;
		overflow: hidden;
		border-left: 1px solid #d7d7d7;
		float: left;
		width: calc(790px - 150px);
	}
	.header__dropdown-more .header__dropdown-list-wrap
	{
		padding: 18px;
	}
	.header__dropdown-list
	{
		float: left;
		width: 160px;
	}
	.header__dropdown-link
	{
		color: #0c0c0c;
		line-height: 30px;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
	}
	.header__item
	{
		float: left;
	}
	.header__item .header__dropdown.is-open
	{
		max-height: 400px;
	}
	.header__item:hover .header__line
	{
		opacity: 1;
		top: 37px;
	}
	.header--small .header__item:hover .header__line
	{
		top: 32px;
	}
	.header__item.is-selected:hover .header__line
	{
		opacity: 0;
		top: 38px;
	}
}
@media (min-width: 768px) and (max-width: 991px)
{
	.header--small .logo
	{
		margin-right: 5px;
	}
	.header__dropdown
	{
		width: 574px;
	}
	.header__dropdown-title
	{
		width: 104px;
	}
	.header__dropdown-list-wrap
	{
		float: left;
		width: calc(574px - 104px);
		margin-left: 0px;
	}
	.header__dropdown-list
	{
		width: 108px;
	}
	.header__dropdown-more .header__dropdown-list
	{
		width: 100%;
	}
	.header__dropdown.header__dropdown-more
	{
		left: -31px;
	}
	body.es_US .header__dropdown.header__dropdown-more
	{
		left: -110px;
	}
	.header__dropdown-more .header__icon-up
	{
		margin-left: 41px;
	}
	body.es_US .header__dropdown-more .header__icon-up
	{
		margin-left: 118px;

	}
	.header__link
	{
		margin: 0 1px;
		padding: 0 3px;
	}
	.header__icon
	{
		margin-left: 10px;
	}
}
/* Header End */
/* Footer Start */
.footer
{
	padding: 50px 0;
	margin-top: 150px;
}
.footer-up
{
	border-bottom: 1px solid #252525;
	overflow: hidden;
	padding-bottom: 45px;
}
.footer__faq
{
	float: left;
	width: 65%;
}
.footer__faq-title
{
	font-weight: 700;
	font-size: 24px;
}
.footer__btns
{
	float: right;
	font-size: 12px;
}
.footer__btn
{
	border: 1px solid #252525;
	color: #a0aab3;
	min-width: 140px;
	text-transform: uppercase;
	margin-bottom: 25px;
}
.footer__btn:first-child
{
	margin-right: 27px;
}
.footer-down
{
	padding-top: 45px;
}
.copyright
{
	float: left;
	width: 50%;
	line-height: 22px;
}
.footer__info
{
	float: left;
	width: 50%;
}
.footer__list-wrap
{
	float: left;
	overflow: hidden;
}
.footer__list
{
	float: left;
	margin-left: 50px;
}
.footer__item
{
	line-height: 32px;
}
.footer__item.is-hidden {
	opacity: 0;
}
.footer__link
{
	color: #a0aab3;
}
.footer__link:hover
{
	text-decoration: underline;
}
.footer--center
{
	text-align: center;
	padding: 32px 0;
}
.footer__link--gap
{
	margin: 0 13px;
	display: inline-block;
	font-weight: 400;
}
.language
{
	float: right;
	cursor: pointer;
	font-size: 12px;
	text-transform: uppercase;
	width: 140px;
	position: relative;
}
.language__btn
{
	min-width: 140px;
	width: 140px;
	color: #a0aab3;
	border: 1px solid #252525;
	position: relative;
	display: block;
	background-color: #0c0c0c;
}
.language__btn-text
{
	display: block;
	text-align: left;
	margin-right: 20px;
	white-space: nowrap;
	overflow: hidden;
}
.language__icon-down
{
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #a0aab3;
	display: inline-block;
	vertical-align: middle;
	margin-left: 4px;
	margin-top: -1px;
	transition: all 0.2s;
}
.language__list
{
	position: absolute;
	top: 0;
	border: 1px solid #252525;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	line-height: 36px;
	width: inherit;
	box-sizing: border-box;
	overflow: hidden;
	height: 92px;
	font-weight: 700;
	background-color: #0c0c0c;
	opacity: 0;
	z-index: -1;
	transition: all 0.2s;
}
.language__item
{
	padding: 0 10px;
}
.language__item:first-child
{
	margin-top: 10px;
}
.language__item:last-child
{
	margin-bottom: 10px;
}
.language__item:hover
{
	opacity: 0.8;
}
.language__item.is-selected
{
	cursor: default;
	opacity: 0.8;
}
.language__item-link
{
	color: #a0aab3;
}
.language.is-open .language__list
{
	z-index: 0;
	opacity: 1;
	top: -91px;
}
.language.is-open .language__icon-down
{
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}
.footer__support
{
	display: block;
	margin: 25px auto 0;
}
@media (max-width: 991px)
{
	.copyright
	{
		float: none;
		width: 100%;
	}
	.footer__info
	{
		float: none;
		width: 100%;
		margin-top: 25px;
	}
	.footer__list-wrap
	{
		margin-left: -50px;
	}
}
@media (max-width: 767px)
{
	.footer {
		margin-top: 0;
	}
	.footer-up
	{
		padding-bottom: 20px;
	}
	.footer__faq,
	.footer__btns
	{
		float: none;
	}
	.footer__btns
	{
		margin-top: 25px;
	}
	.footer__list-wrap
	{
		float: none;
	}
	.language
	{
		float: none;
		margin: 30px auto 0;
	}
	.footer__faq
	{
		width: 100%;
	}
}
@media (max-width: 483px)
{
	.footer__btn
	{
		min-width: 124px;
	}
}
@media (max-width: 360px)
{
	.footer__link
	{
		font-size: 13px;
	}
}
@media (min-width: 768px) and (max-width: 991px)
{
	.footer__faq
	{
		width: 48%;
	}
}
@media (min-width: 992px) and (max-width: 1199px)
{
	.copyright
	{
		width: 46%;
	}
	.footer__info
	{
		width: 54%;
	}
	.footer__list
	{
		margin-left: 20px;
	}
	.footer__faq
	{
		width: 60%;
	}
}
/* Footer End */
/* SnapABug_Button Start */
body.is-overlay-open #launcher
{
	display: none !important;
}
/* SnapABug_Button End */
/* Resetpassword Page Start */
#iptvaccount_section_resetpassword
{
	width: auto;
	max-width: 450px;
	padding-left: 15px;
	padding-right: 15px;
	box-sizing: border-box;
	margin: 20px auto 0;
}
#iptvaccount_section_resetpassword .separator
{
	height: 30px;
	width: 100%;
}
#iptvaccount_section_resetpassword .form-group
{
	margin-bottom: 30px;
}
#iptvaccount_section_resetpassword h1
{
	color: #eee;
	font-weight: 800;
	font-size: 30px;
}
#iptvaccount_section_resetpassword .desc
{
	font-size: 16px;
	color: #a0aab3;
	font-weight: 300;
}
#iptvaccount_section_resetpassword .label
{
	color: #eee;
	font-weight: 700;
	font-size: 14px;
	display: inline-block;
	text-align: left;
	margin-bottom: 5px;
}
#iptvaccount_section_resetpassword input.input
{
	display: block;
	height: 45px;
	padding-left: 6px;
	padding-right: 6px;
	line-height: 45px;
	color: #000;
	font-size: 1.3em;
	font-weight: bold;
	background-color: #fff;
	border: 1px solid #cecece;
	border-radius: 4px;
	width: 100%;
}
#iptvaccount_section_resetpassword .btn
{
	color: #fff;
	background: rgba(0, 196, 115, 1);
}
#iptvaccount_section_resetpassword .reg-link
{
	color: #a0aab3;
	line-height: 50px;
	text-align: center;
	font-weight: 700;
	display: block;
}
#iptvaccount_section_resetpassword .reg-link:hover
{
	text-decoration: underline;
}
#iptvaccount_section_resetpassword .labelErrorDesc
{
	color: #fe0000;
}
/* Resetpassword Page End */
/* Packages Start */
.packages__item
{
	margin-top: 30px;
}
.packages__head
{
	text-align: center;
}
.packages__title
{
	font-weight: 700;
	font-size: 24px;
	color: #eee;
}
.packages__troop
{
	padding: 20px;
	overflow: hidden;
}
.troop__logo
{
	float: left;
	width: 51px;
	margin-right: 20px;
}
.troop__content
{
	float: left;
	width: calc(100% - 51px - 67px - 20px);
	line-height: 20px;
}
.troop__title
{
	color: #eee;
	font-weight: 700;
	font-size: 18px;
}
.icon__troop
{
	float: right;
	background-image: url(../images/icon_troop.png);
	background-image: -webkit-image-set(url(../images/icon_troop.png) 1x, url(../images/icon_troop@2x.png) 2x);
	background-repeat: no-repeat;
	background-position: center center;
	width: 67px;
	height: 40px;
	display: block;
}
.packages__troop
{
	background-image: url(../images/troop_bg.png);
	background-image: -webkit-image-set(url(../images/troop_bg.png) 1x, url(../images/troop_bg@2x.png) 2x);
}
.packages__footer
{
	line-height: 24px;
}
/* disable purchase */
.packages-disable-purchase
{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	position: relative;
}
.packages-disable-purchase .content
{
	width: 690px;
	background: #151515;
	padding: 50px 110px 60px;
	text-align: center;
	position: relative;
}
.packages-disable-purchase .packages-logo
{
	margin-bottom: 30px;
}
.packages-disable-purchase .description
{
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 40px;
}
.packages-disable-purchase .item-text
{
	border-top: 2px solid #464646;
	width: 100%;
	font-size: 13px;
	font-weight: 700;
	padding: 20px 0;
	color: #fff;
}
.packages-disable-purchase .item-text:last-of-type
{
	border-bottom: 2px solid #464646;
}
.packages-disable-purchase .item-text .link
{
	color: #00c473;
	margin-top: 10px;
	display: inline-block;
}
.packages-disable-purchase .modal__icon--close
{
	top: 15px;
	right: 15px;
}
.packages-disable-purchase .modal__icon--close::before,
.packages-disable-purchase .modal__icon--close::after
{
	background: rgba(255, 255, 255, 1);
}
.arrow-back
{
	position: absolute;
	left: 15px;
	top: 15px;
	background-image: url(../images/gray_back.svg);
	width: 16px;
	height: 16px;
}
@media (max-width: 767px)
{
	.troop__content
	{
		width: 100%;
		margin-top: 15px;
	}
	.troop__title
	{
		font-size: 14px;
	}
	.troop__desc
	{
		font-size: 12px;
	}
	.icon__troop
	{
		float: left;
	}
	.packages-disable-purchase
	{
		background: #151515;
		align-items: flex-start;
	}
	.packages-disable-purchase .content
	{
		width: 100%;
		height: 100%;
		padding-top: 100px;
		padding-left: 25px;
		padding-right: 25px;
	}
	.packages-disable-purchase .packages-logo
	{
		margin-bottom: 50px;
	}
	.packages-disable-purchase .description
	{
		margin-bottom: 20px;
	}
	.packages-disable-purchase .item-text
	{
		padding: 25px 0;
	}
}
/* Packages End */
